import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Nav } from "react-bootstrap"
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import {youtube_parser, CustomLinks} from "../../common/utils";
import {ImageModule} from "../../../modules/Image_Module"
import {Vacancies} from "../../../queries/common_use_query"
import VacancyItem from "./VacancyItem"
import "../../Home/News/News"
// Header component

const News = (props) => {
    const { loading, error, data } = Vacancies();

    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: data?.vacancies && data.vacancies.length > 3 ? true : false,
        arrows: false,
        mobileFirst: true,
        pauseOnHover:false,
        autoplay:true,
        autoplaySpeed:3000,
        dots: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    //console.log("job_id", props.job_id)
    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className={`news-section vacancies_wrapper white`}>
                    <Container>
                        <Row>
                            <Col className="news-content ">
                                <div className="news-heading">
                                    <h2>Other Vacancies</h2>
                                </div>

                                {
                                    <Tab.Container defaultActiveKey={"0"}>
                                            
                                            <Tab.Content>
                                                <Tab.Pane eventKey={"0"}>
                                                {data && data.vacancies && data.vacancies.length > 0 && 
                                                    <Slider {...settings}>
                                                    {
                                                        data.vacancies.map((item, index) => {
                                                            return(
                                                                <VacancyItem item={item} />
                                                            )                                                            
                                                        })
                                                    }                                       
                                                    </Slider>
                                                }
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>

            </ScrollAnimation>

        </React.Fragment>
    )
}
export default News
