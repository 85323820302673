import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import {youtube_parser, CustomLinks} from "../../common/utils";
import {ImageModule} from "../../../modules/Image_Module"
import "../../Home/News/News"
// Header component

const News = ({item}) => {
    return (
        <React.Fragment>
            <div className="news-wrapper">                          
                <div className="news-img img-zoom">
                    <Link to={`${CustomLinks.our_people}${CustomLinks.join_us}/${item.URL}/`}>
                        <picture>
                            {item.Image && 
                                <ImageModule ImageSrc={item.Image} altText={item.Title + " - Strettons"} ggfx_results={item.ggfx_results} imagename="vacancies.Image.slider_image" strapi_id={item.id} />
                            }
                        </picture>
                    </Link>
                </div>
                
                <div className="news-details">
                    <h2>
                        <Link to={`${CustomLinks.our_people}${CustomLinks.join_us}/${item.URL}/`}>
                            {item.Title}
                        </Link>
                    </h2>
                </div>
            </div>
        </React.Fragment>
    )
}
export default News
