import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"

import SocialShare from "../common/socialShare";

import Icon_Briefcase from "../../images/icon_briefcase.svg"
import Icon_Mappin from "../../images/icon_mappin.svg"

import RightsideSections from "./RightsideSections"
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from 'html-react-parser';

import CareerForm from "../forms/career-form-popup"

import "../InsightsDetails/PortfolioManagement/Management.scss"
// Header component

const CareerDetails = (props) => {

    const [isOpen,setIsOpen] = useState(false);
    const [Shareicons,setShareicons] = useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if(Shareicons === true) {
          setShareicons(false);
        }
    }

    return (
        <React.Fragment>
                <section className="management-section career_details">
                    <Container>
                        <Row>
                            <Col>
                                {props.details && 
                                <div className="management-wrapper">
                                    <div className="management">
                                        {
                                            props.details.Deparment && 
                                            <span className="portfolio-btn">
                                                {props.details.Deparment}
                                            </span>
                                        }
                                        <h1>{props.details.Title}</h1>
                                    </div>

                                    <div className="management">
                                        {props.details.office && <span className="office"><img src={Icon_Mappin} alt={props.details.office.Name+" - Strettons"}/> {props.details.office.Name}</span>}

                                        {props.details.Service && <span className="office"><img src={Icon_Briefcase} alt={props.details.Service+" - Strettons"} /> {props.details.Service}</span>}
                                        
                                        <div className="btn-wrapper">
                                            <a href="javascript:;" onClick={()=>setIsOpen(true)} className="btn btn-primary">
                                                apply for this job
                                            </a>

                                <div className="share">
                                <a href="javascript:;" onClick={openShareicons} className="btn btn-primary">
                                    share job
                                </a>
                                {Shareicons && (
                                    <SocialShare openShareicons={openShareicons} />
                                )}
                                            </div>
                                        </div>
                                        
                                        <div className="management-details">
                                            <div className="management-info">
                                                {props.details.Content && HTMLReactParser(props.details.Content)}

                                                {props.details.About_Strettons && 
                                                    <>
                                                    <h4>About Strettons</h4>
                                                    {HTMLReactParser(props.details.About_Strettons)}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <RightsideSections details={props.details} />
                                </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>

            <Modal
                id="popup_modal_form"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
            >
                {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Send Your CV</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <CareerForm jobtitle={props.details.Title} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
export default CareerDetails;