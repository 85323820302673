import React, { useState, useEffect } from "react"
import { Link } from "gatsby"


import {ImageModule} from "../../../modules/Image_Module"
import {CustomLinks} from "../../common/utils"

import Modal from "react-bootstrap/Modal"
import MemberForm from "../../forms/member-form"

const PeopleSection = (props) => {

    const [isOpen,setIsOpen] = useState(false);
    const [membDet,setMembDet] = useState('');

    const MemberPopup = (item, popupType) =>{
        setMembDet(item);
        setIsOpen(true);
    }

    return (
        <React.Fragment>
            <div className="people_sect">
                <div className="people_list">
                    {
                        props.Data && props.Data.length > 0 && (
                            props.Data.map((item, index) => {
                                var get_category = item?.people_departments?.length > 0 && item?.people_departments[0]?.Name;
                                
                                return(
                                    <div className="author">
                                        <div className="author-img">
                                            <Link to={`${CustomLinks.our_people}${CustomLinks.find_team}/${item.URL}/`}>
                                                {item.Image &&
                                                    <ImageModule ImageSrc={item.Image} altText={""} ggfx_results={item.ggfx_results} imagename="peoples.Image.home_team_avatar" strapi_id={item.id} />
                                                }
                                            </Link>
                                        </div>
                                        <div className="author-info">
                                            <h3><Link to={`${CustomLinks.our_people}${CustomLinks.find_team}/${item.URL}/`}>{item.Name}</Link></h3>
                                            <div className="profile-info">
                                                <span className="profile">
                                                    {get_category && <Link to={`${CustomLinks.our_people}${CustomLinks.find_team}/${get_category.toLowerCase().replace(/ /g, '-')}/`}>{get_category}</Link>}

                                                    {get_category && item?.Level ? " / " : ""}

                                                    {item?.Level}
                                                </span>
                                            </div>

                                            <div className="contact-us">
                                                <a href={`tel:${item.Phone_Number}`} className="tel">{item.Phone_Number}</a>
                                                <a href={`javascript:;`} onClick={() => MemberPopup(item)} className="mail">{item.Email_ID}</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        )
                    }
                </div>
                {/* {
                    props.Data && props.Data.length > 2 && <h4 className="meet_full_team"><a href={`${CustomLinks.our_people}${CustomLinks.find_team}/`} target="_blank">Meet the full team <i className="icon-more"></i></a></h4>
                } */}

            </div>

            <Modal
                id="popup_modal_form"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
            >
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {membDet.Name}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <MemberForm to_email_id={membDet.Email_ID} to_user_id={membDet.Form_Queue_ID} formName={`Individual Contact - ${membDet.Name}`} formLabel={`Person ${membDet.Name}`} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
export default PeopleSection;
