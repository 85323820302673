import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button, Breadcrumb } from "react-bootstrap"
import { navigate } from "@reach/router";
import ScrollAnimation from "react-animate-on-scroll";
import SocialShare from "../common/socialShare";
import {CustomLinks} from "../common/utils";
import "../InsightsDetails/InsightsLinks.scss"
// Header component

const CareerLinks = (props) => {
    
  const [Shareicons,setShareicons] = useState(false);

  const openShareicons = () => {
    setShareicons(true)
    if (Shareicons === true) {
      setShareicons(false)
    }
  }

  return (
    <React.Fragment>
      <section className="insights-nav">
        <Container>
          <Row>
            <Col>
              <div className="sm-menu-list d-lg-none">
                <Link to={CustomLinks.our_people+CustomLinks.join_us}><span className="sm-text">  &lt; back to careers </span></Link>
                <span className="share social-share-wrapper"><a href="javascript:;" onClick={openShareicons}><i className="icon-share"></i>share</a>
                  {Shareicons && (
                    <SocialShare openShareicons={openShareicons} />
                  )}
                </span>
              </div>
              <div className="breadcrumb d-none d-lg-flex">
                <Breadcrumb>
                  <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                  <li className="breadcrumb-item"><Link to={`${CustomLinks.our_people}${CustomLinks.join_us}`}>careers</Link></li>
                  <li className="breadcrumb-item active"><Link to='/'>{props.page_title}</Link></li>
                </Breadcrumb>

                <span className="share social-share-wrapper">
                  <ScrollAnimation animateIn='bounce' initiallyVisible={true}>
                    <a className="share" href="javascript:;" onClick={openShareicons}><i className="icon-share"></i>share this job</a>
                  </ScrollAnimation>

                  {Shareicons && (
                    <SocialShare openShareicons={openShareicons} />
                  )}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default CareerLinks;
