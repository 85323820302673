import React, {useState, useEffect, useRef, createRef} from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Card, Col } from "react-bootstrap";
import axios from "axios"

import Layout from "../components/layout";
import SEO from "../components/seo";

import CareerLinks from "../components/CareerDetails/CareerLinks";
import CareerDetails from "../components/CareerDetails/CareerDetails";
import OtherVacancies from "../components/InnerPage/Vacancies/OtherVacancies";
import {VacancyDetails} from "../queries/common_use_query"
import {capitalize} from '../components/common/utils';

function CareerDetailsTemplate(props) {

  //console.log("search", search);
  const [details, SetDetails] = useState("");

  const {loading, error, data} = VacancyDetails(props.pageContext?.slug);

  useEffect(()=>{
    data && data.vacancies.length > 0 && SetDetails(data.vacancies[0]);
  },[data])

  var myMetaTitle = "";
  var myMetaDesc = "";
  var myMetaImg = "";

  if( props?.pageContext?.title ) {
      myMetaTitle = capitalize(props.pageContext.title);
      myMetaDesc = capitalize(props.pageContext.title);
  }

  let processedImages = JSON.stringify({});
  const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev";

  if (details?.imagetransforms?.Image_Transforms) {
    processedImages = details?.imagetransforms?.Image_Transforms;
    var resized_images = JSON.parse(processedImages);
    const SrcCftle = details?.Image && details?.Image?.url.substring(details?.Image && details?.Image?.url.indexOf(STAGE_ENV));
    const ProcessedUrl = resized_images[SrcCftle] && resized_images[SrcCftle]?.jpg ? resized_images[SrcCftle]?.jpg['472x300'] : 
                         resized_images[SrcCftle]?.png ? resized_images[SrcCftle]?.png['472x300'] : details?.Image && details?.Image?.url;
    myMetaImg = ProcessedUrl;
  }

  return (
      <Layout GQLPage="" Layout="Without_Banner" classNames={"search-header person-header news_details_wrapper details-header"} popular_search_slug="join-us">
        
        <SEO title={myMetaTitle} description={myMetaDesc} image={myMetaImg}/>
        
        <Helmet bodyAttributes={{ class: `body_cls` }} />

        <CareerLinks page_title={details && details.Title} />

        <CareerDetails details={details} />

        <OtherVacancies job_id={details && details.id} />

      </Layout>
  )
}

export default CareerDetailsTemplate